import { useRouter } from 'next/router';
import { unstable_serialize as unstableSerialize } from 'swr';
import useSWRImmutable from 'swr/immutable';
import { getSearchVolumeKeywords } from 'utils/catalog/category-search-volume/search-volume-keywords';

// SVK = Search Volume Keywords
const useCatalogSVK = ({ isSearchCrawler }: { isSearchCrawler: boolean }) => {
  const router = useRouter();
  const { locale } = router;

  // Run experiment on MY venture only
  const searchVolumeKeywordsSwrKey = unstableSerialize(['search-volume-keywords']);

  const {
    data: searchVolumeKeywords,
    isLoading: isLoadingSearchVolumeKeywords,
    error,
  } = useSWRImmutable(searchVolumeKeywordsSwrKey, () => getSearchVolumeKeywords(locale), {
    keepPreviousData: !isSearchCrawler,
  });

  return {
    // if isSearchCrawler is true, data will be got from server side and storing in cache of swr already,
    // don't need to check for loading
    isLoading: !isSearchCrawler && isLoadingSearchVolumeKeywords,
    data: searchVolumeKeywords || undefined,
    error,
  };
};

export default useCatalogSVK;

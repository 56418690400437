import { useDynamicCatalogContext, useStaticCatalogContext } from 'context/CatalogContext';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { FC, memo } from 'react';
import useCatalogSVK from 'hooks/catalog/useCatalogSVK';
import {
  getCategoryLinksBySearchVolume,
  getTopCategoriesBySearchVolume,
} from 'utils/catalog/category-search-volume/category-search-volume';
import { getLanguage } from 'utils/locale';
import InternalLinks from './RelatedInternalLinks';

const MAX_CATEGORY_LINKS = 30;

const RelatedCategories: FC = () => {
  const { t } = useTranslation('catalog');
  const { locale = '' } = useRouter();
  const language = getLanguage(locale);
  const { isSearchCrawler, pageInfo, seoInfo } = useStaticCatalogContext();
  const { categoryFilter, menuCategories, menuSegments } = useDynamicCatalogContext();

  // Fetch catalog SEO
  const { data: searchVolumeKeywords } = useCatalogSVK({
    isSearchCrawler,
  });

  if (!categoryFilter || !categoryFilter?.Options?.length || !searchVolumeKeywords) {
    return null;
  }

  const topSearchVolume = getTopCategoriesBySearchVolume(
    categoryFilter?.Options,
    searchVolumeKeywords,
    MAX_CATEGORY_LINKS,
  );

  const cateLinks = getCategoryLinksBySearchVolume({
    categories: categoryFilter?.Options,
    menuCategories,
    menuSegments,
    topSearchVolume,
    pageInfo,
    seoInfo,
    language,
  });

  if (!cateLinks || cateLinks.length === 0) {
    return null;
  }

  const formattedCateLinks = cateLinks.map((cate) => ({
    Id: cate.label,
    Name: cate.label,
    Url: cate.cateUrl,
  }));

  return (
    <InternalLinks
      title={t('Shop by Related Categories')}
      links={formattedCateLinks}
    />
  );
};

export default memo(RelatedCategories);

import { ZDTProduct } from '@zalora/doraemon-ts';

/**
 * This functions is used to add Segment before the label of category label
 * Format of anchor text: `${Segment} ${ParentCategory.Label} ${Category.Label}`
 * @param category
 * @returns
 */
export const cateLabelWithSegment = ({
  category,
  parentCategory,
  menuSegments,
  language = 'en',
}: {
  category: ZDTProduct.FilterOptions | ZDTProduct.FilterSuboption;
  parentCategory?: ZDTProduct.FilterOptions;
  menuSegments: Nullishable<ZDTProduct.FilterOptions[]>;
  language?: string;
}) => {
  if (!category.Label) {
    return '';
  }

  const segmentCode = category.SegmentUrls?.[0];

  if (!segmentCode) {
    return category.Label;
  }

  let label = `${category.Label}`;

  // If parent category is not null, add parent category label
  if (parentCategory && parentCategory.Label) {
    label = `${parentCategory.Label} ${category.Label}`;
  }

  // Add segment to category label
  return addSegmentToCategoryLabel(label, segmentCode, menuSegments, language);
};

const addSegmentToCategoryLabel = (
  label: string,
  segmentCode: string,
  menuSegments: Nullishable<ZDTProduct.FilterOptions[]>,
  language: string,
) => {
  if (!segmentCode || label.toLowerCase().includes(segmentCode)) {
    return label;
  }

  const segment = menuSegments?.find((item) => item.Value === segmentCode);

  if (!segment?.Label) {
    return label;
  }

  return language === 'en' ? `${segment.Label}'s ${label}` : `${segment.Label} ${label}`;
};

import { ZDTProduct, ZDTSEO } from '@zalora/doraemon-ts';
import { buildCategoryUrl, buildSubCategoryUrl } from '../build-category-url';
import { CatalogType, PageInfo } from '../catalog-page-type';
import { getRootCategory } from '../catalog-seo';
import { cateLabelWithSegment } from './internal-link-label';

export type CategoryLinkSearchVolume = {
  cateUrl: string;
  label: string;
  searchVolume: number;
};

const getTopSubCategoriesBySearchVolume = (
  category: Nullable<ZDTProduct.FilterOptions>,
  searchVolume: Record<string, number>,
) => {
  if (!category || !category.SubOptions || category.SubOptions.length === 0) {
    return [];
  }

  const subCateUrlWithSearchVolume: {
    value: string;
    searchVolume: number;
  }[] = [];
  const subCategories = category.SubOptions;

  subCategories.forEach((subCategory) => {
    const subCatSegmentUrlKey = subCategory.SegmentUrls?.[0];
    const { Label, Value } = subCategory || {};

    if (!Label || !Value || !subCatSegmentUrlKey) {
      return;
    }

    const lowercaseLabel = Label.toLowerCase().replace(/'s/g, '');
    const labelWithSegment = `${subCatSegmentUrlKey} ${lowercaseLabel}`;

    const cateSearchVolume = searchVolume[labelWithSegment];

    /**
     * Instead of filter out all sub-categories that don't have search volume,
     * we decided to keep them all and get top 30:
     *  - Priority for sub-categories with search volume.
     *  - The rest without search volume will be included based on sub-categories of filter enpoint + with search volume is 0.
     * Purpose: to increase the number of Internal Links for SEO.
     */

    subCateUrlWithSearchVolume.push({
      value: Value,
      searchVolume: cateSearchVolume || 0,
    });
  });

  return subCateUrlWithSearchVolume;
};

/**
 * This function is used to get top categories by search volume:
 * Because the Value of Category/SubCategory is unique, so I use it as a key to store the search volume.
 * const categories = {
 * {
    Label: "Men's Shoes",
    Value: '27',
    ResultCount: 156,
    Selected: false,
    SubOptions: [
      {
        Label: 'Sneakers',
        Value: '413',
        ResultCount: 92,
        CategoryUrlKey: 'sneakers-plimsolls',
        SegmentUrls: ['men'],
      },
    ],
    CategoryUrlKey: 'shoes',
    SegmentUrls: ['men'],
  }
 * So result will be:
 * const topSearchVolume = {
 *    27: 100,
      413: 80,
 * }
 * '27' is the Value of "Men's Shoes",
 * '413' is the Value of "Sneakers"
 */

export const getTopCategoriesBySearchVolume = (
  categories: ZDTProduct.FilterOptions[] | null,
  searchVolume: Record<string, number>,
  topKeywords: number,
) => {
  if (!categories || !searchVolume) {
    return null;
  }

  const cateWithSearchVolume: {
    value: string;
    searchVolume: number;
  }[] = [];

  categories.forEach((category) => {
    const segmentUrlKey = category.SegmentUrls?.[0];

    if (!category.Label || !category.Value || !segmentUrlKey) {
      return;
    }

    /**
     * The Google's algorithms are sophisticated enough to understand the semantic similarity between them "women's sandals" and "women sandals", 
     * So instead of using apostrophes for matching the keywords, I decided to remove it out. Why?
        - Search result are the same
        - I remove the "women's sandals" and only keep "women sandals" to reduce the size of cms file.
     */

    // lowercaseLabel is used to match with searchVolume
    const lowercaseLabel = category.Label.toLowerCase().replace(/'s/g, '');
    const cateSearchVolume = searchVolume[lowercaseLabel];

    // Running with sub category links first
    const subCateUrlWithSearchVolume = getTopSubCategoriesBySearchVolume(category, searchVolume);

    if (subCateUrlWithSearchVolume.length > 0) {
      cateWithSearchVolume.push(...subCateUrlWithSearchVolume);
    }

    /**
     * Instead of filter out all categories that don't have search volume,
     * we decided to keep them all and get top 30:
     *  - Priority for categories with search volume.
     *  - The rest without search volume will be included based on categories of filter enpoint + with search volume is 0.
     * Purpose: to increase the number of Internal Links for SEO.
     */

    cateWithSearchVolume.push({
      value: category.Value,
      searchVolume: cateSearchVolume || 0,
    });
  });

  if (cateWithSearchVolume.length === 0) {
    return null;
  }

  // Sort by search volume and only return top 30 categories
  const sortedCateUrlWithSearchVolume = cateWithSearchVolume
    .sort((a, b) => {
      return b.searchVolume - a.searchVolume;
    })
    .slice(0, topKeywords);

  const objectCategoriesWithSearchVolume = sortedCateUrlWithSearchVolume.reduce(
    (acc, cur) => {
      acc[cur.value] = cur.searchVolume;

      return acc;
    },
    {} as Record<string, number>,
  );

  return objectCategoriesWithSearchVolume;
};

const getSubCategoryLinksBySearchVolume = ({
  category,
  menuSegments,
  topSearchVolume,
  pageInfo,
  language,
}: {
  category: Nullable<ZDTProduct.FilterOptions>;
  menuSegments: Nullishable<ZDTProduct.FilterOptions[]>;
  topSearchVolume: Record<string, number>;
  pageInfo: PageInfo;
  language?: string;
}) => {
  if (!category || !category.SubOptions || category.SubOptions.length === 0) {
    return [];
  }

  const cateSegmentUrlKey = category.SegmentUrls?.[0];
  const subCategories = category.SubOptions;
  const subCateUrlWithSearchVolume: CategoryLinkSearchVolume[] = [];
  const { categoryId = '', segment = '' } = pageInfo;

  subCategories.forEach((subCategory) => {
    const { Label, Value } = subCategory || {};

    if (!Label || !Value || !category.Value || !cateSegmentUrlKey) {
      return;
    }

    const cateSearchVolume = topSearchVolume[Value];

    if (!cateSearchVolume && cateSearchVolume !== 0) {
      return;
    }

    // Create sub category from subOptions
    const subOptions = {
      CategoryUrlKey: subCategory.CategoryUrlKey,
      Label: subCategory.Label,
      ResultCount: subCategory.ResultCount,
      Selected: false,
      SubOptions: [],
      Value: subCategory.Value,
      SegmentUrls: subCategory.SegmentUrls,
    };

    /**
     * If categoryId && segment in PageInfo is not null, use categoryId for buildSubCategoryUrl
     * Sample: https://www.zalora.com.ph/c/accessories/c-79
     *  */
    const cateId = segment && categoryId ? categoryId : category.Value;

    // Create sub category url
    const subLink = buildSubCategoryUrl({
      categoryItem: subOptions,
      pageInfo: {
        segment: cateSegmentUrlKey,
        categoryId: cateId,
        catalogType: CatalogType.CATALOG,
      },
      // Fake pathName to get category key from buildSubCategoryUrl
      pathName: `/c/${cateSegmentUrlKey}/${category.CategoryUrlKey}/fake`,
      queryParams: '',
    });

    subCateUrlWithSearchVolume.push({
      label: cateLabelWithSegment({
        category: subCategory,
        parentCategory: category,
        menuSegments,
        language,
      }),
      cateUrl: subLink,
      searchVolume: cateSearchVolume,
    });
  });

  return subCateUrlWithSearchVolume;
};

export const getCategoryLinksBySearchVolume = ({
  categories,
  menuCategories,
  menuSegments,
  topSearchVolume,
  pageInfo,
  seoInfo,
  language,
}: {
  categories: ZDTProduct.FilterOptions[] | null;
  menuCategories: Nullishable<ZDTProduct.FilterOptions[]>;
  menuSegments: Nullishable<ZDTProduct.FilterOptions[]>;
  topSearchVolume: Record<string, number> | null;
  pageInfo: PageInfo;
  seoInfo: ZDTSEO.CatalogInfo;
  language?: string;
}) => {
  if (!categories || !topSearchVolume) {
    return null;
  }

  /**
   * return NULL if:
   *
   * - is brand page
   * - menuCategories is null
   * - menuSegments is null || menuSegments.length <= 2
   *
   *  */

  if (
    pageInfo.brandId &&
    !menuCategories &&
    ((menuSegments && menuSegments.length <= 2) || !menuSegments)
  ) {
    return null;
  }

  const cateUrlsWithSearchVolume: CategoryLinkSearchVolume[] = [];

  categories.forEach((category) => {
    const segmentUrlKey = category.SegmentUrls?.[0];

    if (!category.Value || !segmentUrlKey) {
      return;
    }

    // 1. Running with subCategory first
    const subCateUrlWithSearchVolume = getSubCategoryLinksBySearchVolume({
      category,
      menuSegments,
      topSearchVolume,
      pageInfo,
      language,
    });

    if (subCateUrlWithSearchVolume.length > 0) {
      cateUrlsWithSearchVolume.push(...subCateUrlWithSearchVolume);
    }

    // 2. Then running category
    const cateSearchVolume = topSearchVolume[category.Value];

    if (!cateSearchVolume && cateSearchVolume !== 0) {
      return;
    }

    let cateUrl = '';

    if (
      (pageInfo.categoryId && !category.SubOptions) ||
      (pageInfo.categoryId && category.SubOptions && pageInfo.segment) ||
      (!category.SubOptions && pageInfo.segment && menuCategories)
    ) {
      const { categoryId = '', segment = '' } = pageInfo;

      let parentCate = {
        Value: segment && categoryId ? categoryId : category.Value,
        CategoryUrlKey: category.CategoryUrlKey,
      };

      /**
       * Get the first menu category that has result count
       *
       * DOR will return the first menu category that is root category,
       * if there are no result, use previous parentCate
       *  */

      const menuCate = menuCategories?.find((item) => item.ResultCount);

      if (menuCate && menuCate.Value && menuCate.CategoryUrlKey) {
        const rootCate = getRootCategory(true, seoInfo);

        parentCate = {
          Value: rootCate?.Id || menuCate.Value,
          CategoryUrlKey: rootCate?.UrlKey || menuCate.CategoryUrlKey,
        };
      }

      // Create sub category url
      cateUrl = buildSubCategoryUrl({
        categoryItem: category,
        pageInfo: {
          segment: segmentUrlKey,
          categoryId: parentCate.Value,
          catalogType: CatalogType.CATALOG,
        },
        // Fake pathName to get category key from buildSubCategoryUrl
        pathName: `/c/${segmentUrlKey}/${parentCate.CategoryUrlKey}/fake`,
        queryParams: '',
      });
    } else {
      cateUrl = buildCategoryUrl({
        pageInfo: {
          segment: segmentUrlKey,
          catalogType: CatalogType.CATALOG,
        },
        category: category as unknown as ZDTProduct.FilterOptions,
        pathName: '',
      });
    }

    cateUrlsWithSearchVolume.push({
      label: cateLabelWithSegment({ category, menuSegments, language }),
      cateUrl,
      searchVolume: cateSearchVolume,
    });
  });

  return cateUrlsWithSearchVolume;
};
